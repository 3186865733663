    .Contact-Section{
        // width: 100vw;
        // padding: 0px 2rem 0px 2rem;
        // height: 95.5px;
        // border-bottom: 1px solid #999999;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // position:fixed;
        // background-color: white;
        // top: 0;
        z-index: 1;
    }
.header-container{
    // border: 1px solid red;
    width: 100%;
    display: flex;    
    justify-content: space-between;
    align-items: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    .logo-storename{
        display: flex;
        align-items: center;
        gap: 20px;
        color: rgb(10, 121, 149);
        .link{
            text-decoration: none;
            color: black;
        }
        .header-logo{
            width: 70px;
            height: 60px;
            border-radius: 6px;
            // margin-right: 1rem;
        }
        .store-name{
            // padding-top: 8px;
            font-size: 32px;
            line-height: 36px;
            font-weight: 800;
            color: #0293A8;
        }
        .tag-line{
            font-size: 12px;
            font-weight: 600;
        }
    }
        .profile-cart-container{
            // margin-right: 2rem;
            // width: 21vw;
            // height: 76%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-decoration: none;
            // flex-shrink: 0;
            gap: 30px;
            .link{
                color: #000;
                padding: 0px 15px;
                text-decoration: none;

            }
                .profile-container{
                    // height: 80%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    gap: 5px;
                    .icon{
                        font-size: 32px;
                        color: #0293A8;
                        // border: 2px solid red;
                        border-radius: 50%;
                        padding: 4px;
                        // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25), 1px 2px 3px rgba(0, 0, 0, 0.25);
                    }
                    p{
                        // margin-bottom: -8px;
                        font-size: 16px;
                    }
                    
                }
            .cart-div{
                // height: 80%;
                display: flex;
                flex-direction: column;
                align-items: center;
                // justify-content: space-between;
                font-weight: bold;
                font-size: 14px;
                gap: 5px;
                .cart-items-count{
                    background-color: #e66d60;
                    padding: 1px 7px 1px 7px;
                    border: 0px solid;
                    border-radius: 50%;
                    // margin-right: -25px;
                    // margin-top: -62px;
                    font-size: 14px;
                    text-align: center;
                    color: rgb(242, 242, 242);
                    text-decoration: none;
                    position: absolute;
                    top: -5px;
                    right: 10px;
                }.cartNotEmpty{
                    background-color: green;
                    padding: 1px 7px 1px 7px;
                    border: 0px solid;
                    border-radius: 50%;
                    // margin-right: -25px;
                    // margin-top: -62px;
                    font-size: 14px;
                    text-align: center;
                    color: rgb(242, 242, 242);
                    text-decoration: none;
                    position: absolute;
                    top: -5px;
                    right: 10px;}
                .icon{
                    font-size: 30px;
                    color: #0293A8;
                    // border: 2px solid red;
                    border-radius: 50%;
                    padding: 4px;
                    // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25), 1px 2px 3px rgba(0, 0, 0, 0.25);
                }
                p{
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            
        }
}

.header-logo:hover{
    transform: scale(1.1);
}

.icon:hover{
    transform: scale(1.1);
    color: rgb(10, 121, 149);}

.cart-div{
    transform: scale(1.1);
    color: #fc8b4e;
}


@media screen and (max-width: 820px) {
    .header-container{
        // height: 70px;
        .logo-storename{
            // margin-left: 7px !important;
            .header-logo{
                width: 40px;
                height: 40px;
                border-radius: 6px;
                margin-right: 1rem;
                margin-left: 1rem;
            }
            .store-name{
                font-weight: bold;
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 0px !important;
            }
            .store-tagline{
                margin-top: 2px;
                display: inline-block;
                font-size: 12px !important;
            }
        }
            .profile-cart-container{
                display: flex;
                justify-content: start;
                align-items: center;
                // margin-left: 11px;
                width: auto;
                // margin-right: 20px !important;
                .profile-container{
                    justify-content: center !important;
                }
                .icon{
                    font-size: 26px !important;
                }
                .Login{
                    display: none;
                }
                .link{
                    color: #000;
                    padding: 0px 6px;
                }
                .cart-div{
                    justify-content: center;
                    .cart-items-count{
                        margin: 0 !important;
                        position: absolute;
                        top: -5px;
                        right: -5px;
                    }
                    .icon{
                        font-size: 26px !important;
                    }
                    .login{
                        display: none;
                    }
                }
            }
    }
}

@media screen and (width <= 450px) {
    .tag-line{
        display: none;
    }
}



.headerContainer{
    padding: 1px 40px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    position: fixed;
    gap: 10px;
    z-index: 99999;
    background-color: white;
    width: 100%;
    top: 0;

    hr{
        margin: 0;
        // border: unset;
        height: unset;
        // opacity: unset;
    }
}

@media screen and (width <= 620px) {
    .header-container .profile-cart-container .cart-div .cart-items-count{
        font-size: 12px !important;
    }
    .Contact-Section{
        // padding: 10px 30px !important;
        .contact{
            font-size: 12px !important;
        }
    }
    .header-container .logo-storename .header-logo{
        margin: unset;
    }
    .header-container .logo-storename{
        width: unset !important;
        margin: unset !important;
        // gap: 5px;
    }
    .header-container .profile-cart-container{
        margin: unset;
    }
    .header-container .profile-cart-container{
        gap: 20px;
    }
}

@media screen and (width <= 440px) {
    .header-container{
        // flex-direction: column;
        gap: 20px;
        // padding: 10px;
        align-items: center;
    }
    .headerContainer{
        padding: 10px 30px !important;
    }
    .header-container .logo-storename{
        gap: 10px !important;
    }
    .header-container .profile-cart-container{
        gap: 10px !important;
    }
    
}

@media screen and (width <= 340px) {
    .header-container{
        flex-direction: column;
    }
}
  

// 

.header-container-bottom{
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: space-evenly;
    cursor: pointer;
    
        .link{
            text-decoration: none;
            color: black;
        }
       
        .profile-cart-container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-decoration: none;
            gap: 30px;
            .link{
                color: #000;
                padding: 0px 15px;
                text-decoration: none;

            }
                .profile-container{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    gap: 5px;
                    .icon{
                        font-size: 32px;
                        color: #0293A8;
                        border-radius: 50%;
                        padding: 4px;
                    }
                    p{
                        font-size: 16px;
                    }
                    
                }
            .cart-div{
                display: flex;
                flex-direction: column;
                align-items: center;
                font-weight: bold;
                font-size: 14px;
                gap: 5px;
                .cart-items-count{
                    background-color: #e66d60;
                    padding: 1px 7px 1px 7px;
                    border: 0px solid;
                    border-radius: 50%;
                    font-size: 14px;
                    text-align: center;
                    color: rgb(242, 242, 242);
                    text-decoration: none;
                    position: absolute;
                    top: -5px;
                    right: 10px;
                }.cartNotEmpty{
                    background-color: green;
                    padding: 1px 7px 1px 7px;
                    border: 0px solid;
                    border-radius: 50%;
                    font-size: 14px;
                    text-align: center;
                    color: rgb(242, 242, 242);
                    text-decoration: none;
                    position: absolute;
                    top: -5px;
                    right: 10px;}
                .icon{
                    font-size: 30px;
                    color: #0293A8;
                    border-radius: 50%;
                    padding: 4px;
                }
                p{
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            
        }
}
.bottom{
    // border: 1px solid red;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    position: fixed;
    gap: 10px;
    z-index: 99999;
    background-color: white;
    width: 100%;
    bottom: 0;
}
.new-what {
    position: absolute;
    right: 20px;
    background-color: #25d366;
    border-radius: 12px;
  }

.new-what2{
    color: black;
    border-radius: 20%;
}
@media (max-width:400px){
    .profile-cart-container{
        gap: 2px !important;
    }
}